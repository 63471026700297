/* TODO: Remove in XD-13966 */
import * as React from "react";
import { BRAND_FACET_ATTRIBUTE_NAME } from "../../../Search/Constants";
import { useSearchContext } from "../../../Search/SearchState";
import {
  isDistinctFacet,
  isRangeFacet,
  updateDistinctFilter,
} from "../../FilterHelper";
import { verifyRangeFilter } from "./filter-accordion-helper";
import { DistinctFilterAccordion } from "./FilterAccordions/DistinctFilterAccordion";
import { LegacyRangeFilterAccordion } from "./FilterAccordions/RangeFilterAccordion/LegacyRangeFilterAccordion";
import { conditionallyRemovePriceFacet } from "../FilterMenu.helper";
import { splitArray } from "../../../../utils/xxl-array";
import { getCategoryNameForTracking } from "../../tracking-helper";
import type { OnChangeFilterProps } from "./FilterAccordions/DistinctFilterAccordion/types";

const createRange = (max: number, min: number) => ({ max, min });

const shouldBeSearchable = (attributeName?: string): boolean =>
  attributeName === BRAND_FACET_ATTRIBUTE_NAME;

export const LegacyFilters = ({
  isFilterBar,
  numberOfVisibleFilters,
}: {
  isFilterBar: boolean;
  numberOfVisibleFilters: number | null;
}) => {
  const { dispatch, state } = useSearchContext();
  const {
    filteredFacets,
    isClearFiltersClicked,
    isFetchingNewSearchResult,
    longTailFacets = null,
    longTailPattern = null,
    query = "",
    selectedFilters,
    searchData,
  } = state;
  const { results } = searchData ?? {};
  const { count: totalHits = 0 } = results ?? {};
  const editedFilters = conditionallyRemovePriceFacet(filteredFacets);
  const [alwaysVisibleFilters] =
    typeof numberOfVisibleFilters === "number"
      ? splitArray(editedFilters, numberOfVisibleFilters)
      : [editedFilters];
  const categoryName = getCategoryNameForTracking(state);
  const handleChangeFilterValue = ({
    action,
    id,
    values,
  }: OnChangeFilterProps) => {
    if (
      action === "addLongtail" ||
      action === "removeAllWithLongtailUrl" ||
      action === "removeAll" ||
      action === "removeLongtail"
    ) {
      throw Error("Unsupported action in legacy filters");
    }
    updateDistinctFilter(id, dispatch, action, values);
  };

  return (
    <>
      {alwaysVisibleFilters.map((filter, index) => {
        const { attributeName } = filter;

        if (attributeName === undefined) {
          throw new Error("attributeName is undefined.");
        }

        if (isRangeFacet(filter)) {
          const { max, min, name, selectedMax, selectedMin } =
            verifyRangeFilter(filter);

          return (
            <LegacyRangeFilterAccordion
              accordionProps={{
                id: attributeName,
                label: name,
              }}
              attributeName={attributeName}
              category={categoryName}
              isFilterBar={isFilterBar}
              key={`${filter.name ?? ""}_${index}`}
              range={createRange(max, min)}
              searchQuery={query}
              selectedMax={selectedMax}
              selectedMin={selectedMin}
            />
          );
        }

        if (isDistinctFacet(filter)) {
          return (
            <DistinctFilterAccordion
              attributeName={attributeName}
              category={categoryName}
              filterItems={filter.items}
              filterName={filter.name ?? `name-${index}`}
              isClearFiltersClicked={isClearFiltersClicked}
              isFetchingNewSearchResult={isFetchingNewSearchResult}
              isFilterBar={isFilterBar}
              isSearchable={shouldBeSearchable(filter.attributeName)}
              key={`${filter.name ?? ""}_${index}`}
              longTailFacets={longTailFacets}
              longTailPattern={longTailPattern}
              onChangeFilter={handleChangeFilterValue}
              searchQuery={query}
              selectedFilters={selectedFilters}
              totalHits={totalHits}
            />
          );
        }

        throw new Error(
          `[Filters] Filter with attributeName "${attributeName}" is not valid.`
        );
      })}
    </>
  );
};
