/* eslint-disable @typescript-eslint/no-use-before-define */
import type {
  AttributeNameValuePair,
  EntitySortingParameter,
} from "@xxl/search-api";
import {
  FacetTypeEnum,
  EntitySortingParameterTypeEnum,
  SearchRequestProviderEnum,
} from "@xxl/search-api";
import type { SortData } from "../Common";
import {
  SORTS_FOR_CAMPAIGN,
  SORTS_FOR_CAMPAIGN_LOOP_SEARCH,
  SORTS_FOR_SEARCH,
} from "../Common/Model";
import {
  BRAND_CODE_ATTRIBUTE_NAME_FOR_LOOP,
  BRAND_CODE_ATTRIBUTE_NAME_FOR_SOLR,
  CAMPAIGN_ATTRIBUTE_NAME,
  CAMPAIGN_LOOP_ATTRIBUTE_NAME,
  CATEGORY_ATTRIBUTE_NAME,
  DEFAULT_CAMPAIGN_SORT,
  DEFAULT_PAGE_SIZE,
  DEFAULT_SEARCH_SORT,
  INITIAL_PAGE_NUMBER,
} from "./Constants";
import type { SearchProps } from "./Search";
import type { FacetList, State } from "./SearchState";
import {
  getQueryParameterActions,
  parseSearchParams,
  toEntitySortingParameterArray,
} from "./SearchStateUrlHelper";
import { createLongTailTitle } from "./SearchStateFilterHelper";
import { SORTS_FOR_CATEGORY_LOOP_SEARCH } from "../Common/Model/SortData";
import type {
  CampaignPageResult,
  LongTailData,
  CampaignPageResultV2,
  CampaignPageContentData,
  CategoryData,
  CampaignCategoryData,
} from "@xxl/frontend-api";
import { hasValue, isNotNullOrUndefined } from "@xxl/common-utils";
import type { DistinctFacetParameter, Filter } from "../../utils/data-types";
import type { CampaignData } from "../CategorySelector/Campaign/campaign-category-selector-helper";

export const getSearchProvider = (
  isSolrProvider: boolean
): SearchRequestProviderEnum =>
  isSolrProvider
    ? SearchRequestProviderEnum.solr
    : SearchRequestProviderEnum.loop;

interface InitialSearchStateStrategy {
  accepts?(initialSearchStateData: SearchProps): boolean;

  getInitialSearchState(initialSearchStateData: SearchProps): State;
}

export const initialStaticSearchState: State = {
  brandData: null,
  cacheableCampaignSearchQuery: false,
  campaignData: null,
  campaignTotalProductsCount: 0,
  categoryContent: null,
  categoryPath: [],
  categoryInfo: null,
  expandedTopFilter: "",
  fetchMoreProductsCount: 0,
  filteredFacets: <FacetList>[],
  forceSolrAsProvider: false,
  guides: [],
  hiddenProductsNumber: 0,
  initialSolrFilters: <DistinctFacetParameter[]>[],
  initialSorts: <SortData[]>[],
  isCampaignPage: false,
  isClearFiltersClicked: false,
  isFetchingCategoryData: false,
  isFetchingMoreProducts: false,
  isFetchingNewSearchResult: false,
  isFinalSearchFailed: false,
  isHistoryBackEvent: false,
  isInitialRenderFromServer: false,
  isInitialRequest: true,
  isSearchingAgainstFallback: false,
  isSearchResultPage: false,
  longTailTitle: null,
  makeSense: true,
  pageBaseUrl: null,
  page: INITIAL_PAGE_NUMBER,
  pageSize: DEFAULT_PAGE_SIZE,
  productListingSmallBanner: null,
  provider: SearchRequestProviderEnum.loop,
  query: "",
  queryParameterActions: <string[]>[],
  relativePageUrl: null,
  searchData: null,
  searchSuggestions: [],
  selectedCategoryCode: null,
  selectedFilterCount: 0,
  selectedFilters: [],
  selectedSort: <EntitySortingParameter[]>[],
  showAllFilters: false,
  siteId: "",
  sorts: <SortData[]>[],
  tabs: [],
  togglePlpOneCardContentComponent: false,
  isBot: false,
  buyingGuideData: null,
};

type GetAttributesForSearchRequestProps = {
  categoryId?: string;
  isSolrProvider?: boolean;
  brandCode?: string;
  isCacheableCampaignSearchQuery?: boolean;
  campaignId?: string;
  additionalCampaignIds?: string[];
};

export function getAttributesForSearchRequest({
  categoryId,
  brandCode,
  isSolrProvider = false,
  isCacheableCampaignSearchQuery = false,
  campaignId,
  additionalCampaignIds,
}: GetAttributesForSearchRequestProps): AttributeNameValuePair | undefined {
  return isNotNullOrUndefined(categoryId)
    ? {
        name: CATEGORY_ATTRIBUTE_NAME,
        value: categoryId,
      }
    : isNotNullOrUndefined(brandCode)
      ? {
          name: isSolrProvider
            ? BRAND_CODE_ATTRIBUTE_NAME_FOR_SOLR
            : BRAND_CODE_ATTRIBUTE_NAME_FOR_LOOP,
          value: brandCode,
        }
      : isCacheableCampaignSearchQuery &&
          isNotNullOrUndefined(campaignId) &&
          !isSolrProvider
        ? {
            name: CAMPAIGN_LOOP_ATTRIBUTE_NAME,
            value: isNotNullOrUndefined(additionalCampaignIds)
              ? additionalCampaignIds.concat([campaignId])
              : campaignId,
          }
        : undefined;
}

const getCampaignDataBasedOnVersion = (
  campaignPageResult?: CampaignPageResult,
  campaignPageResultV2?: CampaignPageResultV2
) => {
  return isNotNullOrUndefined(campaignPageResultV2)
    ? getCampaignDataV2(campaignPageResultV2.categoryData)
    : isNotNullOrUndefined(campaignPageResult)
      ? getCampaignData(
          campaignPageResult.campaignPageContent,
          campaignPageResult.categories
        )
      : null;
};

const getCampaignProductsCountBasedOnVersion = (
  campaignPageResult?: CampaignPageResult,
  campaignPageResultV2?: CampaignPageResultV2
) => {
  return (
    campaignPageResultV2?.categoryData?.totalProductsCount ??
    campaignPageResult?.campaignPageContent?.totalProductsCount ??
    0
  );
};

abstract class AbstractInitialSearchStateStrategy {
  getInitialSearchState(initialSearchStateData: SearchProps): State {
    const { pageBaseUrl, relativePageUrl } = initialSearchStateData;
    const pageUrl =
      typeof window !== "undefined"
        ? window.location.href
        : `${pageBaseUrl ?? ""}${relativePageUrl ?? ""}`;
    const { searchParams } = new URL(pageUrl);
    const {
      categoryPath,
      selectedSort: selectedSortFromQueryParams,
      query,
      page,
      forceSolrAsProvider,
      selectedCategoryCode,
      selectedFilters,
      forceShowAll,
    } = parseSearchParams(searchParams);

    const { initialSort } = initialSearchStateData;
    const selectedSort =
      selectedSortFromQueryParams.length > 0
        ? selectedSortFromQueryParams
        : toEntitySortingParameterArray(initialSort ?? null);
    const queryParameterActions = getQueryParameterActions();
    const {
      brandCode,
      brandName,
      campaignPageResult,
      campaignPageResultV2,
      categoryId,
      togglePlpOneCardContentComponent,
      additionalCampaignIds,
    } = initialSearchStateData;
    const isSolrProvider =
      forceSolrAsProvider || initialSearchStateData.forceSolrAsProvider;
    const isCacheableCampaignSearchQuery =
      initialSearchStateData.cacheableCampaignSearchQuery;
    const isCampaignPage = Boolean(initialSearchStateData.campaignId);

    const mergedFilters = mergeFacetsWithFilters(
      selectedFilters,
      initialSearchStateData.longTailFacets
    );

    const campaignData = getCampaignDataBasedOnVersion(
      campaignPageResult,
      campaignPageResultV2
    );
    const campaignTotalProductsCount = getCampaignProductsCountBasedOnVersion(
      campaignPageResult,
      campaignPageResultV2
    );

    return {
      ...initialStaticSearchState,
      attribute: getAttributesForSearchRequest({
        brandCode,
        categoryId,
        isSolrProvider,
        additionalCampaignIds,
      }),
      brandCode: brandCode,
      brandName: brandName,
      cacheableCampaignSearchQuery: isCacheableCampaignSearchQuery,
      campaignId: initialSearchStateData.campaignId,
      campaignData,
      campaignTotalProductsCount,
      categoryPath,
      categoryId: categoryId,
      forceSolrAsProvider: isSolrProvider,
      forceShowAll,
      guides: initialSearchStateData.relatedGuides,
      hideHeader: initialSearchStateData.hideHeader,
      isInitialRenderFromServer:
        initialSearchStateData.isInitialRenderFromServer,
      initialSolrFilters: [],
      initialSorts: SORTS_FOR_SEARCH,
      isCampaignPage,
      longTailFacets: initialSearchStateData.longTailFacets,
      longTailPattern: initialSearchStateData.longTailPattern ?? undefined,
      longTailTitle: createLongTailTitle(
        mergedFilters,
        initialSearchStateData.longTailFacets
      ),
      page,
      pageBaseUrl: initialSearchStateData.pageBaseUrl,
      provider: getSearchProvider(isSolrProvider),
      query,
      queryParameterActions,
      relativePageUrl: initialSearchStateData.relativePageUrl,
      searchData: initialSearchStateData.searchData,
      selectedCategoryCode,
      selectedFilters: mergedFilters,
      selectedSort,
      siteId: initialSearchStateData.siteId,
      sorts: SORTS_FOR_SEARCH,
      tabs: [],
      togglePlpOneCardContentComponent,
    };
  }
}

class DefaultInitialSearchStateStrategy
  extends AbstractInitialSearchStateStrategy
  implements InitialSearchStateStrategy
{
  getInitialSearchState(initialSearchStateData: SearchProps): State {
    const commonState = super.getInitialSearchState(initialSearchStateData);
    const selectedSort: EntitySortingParameter[] = [
      {
        type: EntitySortingParameterTypeEnum.custom,
        customName: commonState.forceSolrAsProvider
          ? DEFAULT_CAMPAIGN_SORT
          : DEFAULT_SEARCH_SORT,
      },
    ];
    const defaultState = {
      ...{
        selectedSort:
          commonState.selectedSort.length > 0
            ? commonState.selectedSort
            : selectedSort,
      },
    };
    return { ...commonState, ...defaultState };
  }
}

function getInitialSolrFilters(
  initialSearchStateData: SearchProps
): DistinctFacetParameter[] {
  return hasValue(initialSearchStateData.campaignId) &&
    (initialSearchStateData.forceSolrAsProvider ||
      !initialSearchStateData.cacheableCampaignSearchQuery)
    ? [
        {
          attributeName: CAMPAIGN_ATTRIBUTE_NAME,
          selected: [initialSearchStateData.campaignId],
        },
      ]
    : [];
}

class CampaignInitialSearchStateStrategy
  extends AbstractInitialSearchStateStrategy
  implements InitialSearchStateStrategy
{
  static accepts(initialSearchStateData: SearchProps): boolean {
    return Boolean(initialSearchStateData.campaignId);
  }

  getInitialSearchState(initialSearchStateData: SearchProps): State {
    const commonState = super.getInitialSearchState(initialSearchStateData);
    const isSolrProvider = commonState.forceSolrAsProvider;
    const { brandCode, categoryId, campaignId, additionalCampaignIds } =
      initialSearchStateData;
    const isCacheableCampaignSearchQuery =
      commonState.cacheableCampaignSearchQuery;

    const sortData =
      commonState.cacheableCampaignSearchQuery && !isSolrProvider
        ? SORTS_FOR_CAMPAIGN_LOOP_SEARCH
        : SORTS_FOR_CAMPAIGN;

    const campaignState = {
      initialSolrFilters: getInitialSolrFilters(initialSearchStateData),
      attribute: getAttributesForSearchRequest({
        categoryId,
        brandCode,
        isSolrProvider,
        isCacheableCampaignSearchQuery,
        campaignId,
        additionalCampaignIds,
      }),
      provider: isCacheableCampaignSearchQuery
        ? getSearchProvider(isSolrProvider)
        : SearchRequestProviderEnum.solr,
      selectedSort:
        commonState.selectedSort.length > 0
          ? commonState.selectedSort
          : [sortData[0].asEntitySortingParameter()],
      sorts: sortData,
      initialSorts: sortData,
    };
    return { ...commonState, ...campaignState };
  }
}

class CategoryInitialSearchStateStrategy
  extends AbstractInitialSearchStateStrategy
  implements InitialSearchStateStrategy
{
  static accepts = ({ categoryId }: SearchProps): boolean =>
    categoryId !== undefined;

  getInitialSearchState(initialSearchStateData: SearchProps): State {
    const { buyingGuideData } = initialSearchStateData;
    const commonState = super.getInitialSearchState(initialSearchStateData);
    const isSolrProvider = commonState.forceSolrAsProvider;

    const sorts = isSolrProvider
      ? commonState.sorts
      : SORTS_FOR_CATEGORY_LOOP_SEARCH;
    const selectedSort =
      commonState.selectedSort.length > 0
        ? commonState.selectedSort
        : [sorts[0].asEntitySortingParameter()];
    const categoryState = {
      sorts,
      selectedSort,
      initialSorts: sorts,
      buyingGuideData,
    };
    return { ...commonState, ...categoryState };
  }
}

export const getInitialStateStrategy = (
  initialSearchStateData: SearchProps
): InitialSearchStateStrategy => {
  const strategy =
    [
      CampaignInitialSearchStateStrategy,
      CategoryInitialSearchStateStrategy,
    ].find((candidate) => candidate.accepts(initialSearchStateData)) ??
    DefaultInitialSearchStateStrategy;
  return new strategy();
};

const mergeFacetsWithFilters = (
  selectedFilters: Filter[],
  longTailFacets: LongTailData[] = []
): Filter[] => {
  const selectedLongTailFilters = longTailFacets
    .filter(
      ({ id, values }) =>
        values.length > 0 &&
        selectedFilters.every(({ attributeName }) => attributeName !== id)
    )
    .map(({ id: attributeName, values: selected }) => ({
      attributeName,
      selected,
      type: FacetTypeEnum.distinct,
    }));
  return [...selectedFilters, ...selectedLongTailFilters];
};

export const getCampaignData = (
  content: CampaignPageContentData = {},
  categories: CategoryData[] = []
): CampaignData => {
  const {
    categoryContentsIds,
    ipaperLink,
    level1Categories,
    bannerHeader,
    bannerText,
    productListingSmallBannersIds,
  } = content;
  const { totalProductsCount = 0 } = content;
  return {
    categories,
    categoryContentsIds,
    ipaperLink,
    bannerHeader,
    bannerText,
    level1Categories,
    totalProductsCount,
    productListingSmallBannersIds,
  };
};

const getCampaignDataV2 = ({
  totalProductsCount = 0,
  categories = [],
}: CampaignCategoryData = {}): CampaignData => ({
  categories,
  totalProductsCount,
});
