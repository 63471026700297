import styled from "@emotion/styled/macro";
import { MQ } from "../../../../../../../styles/helpers";
import { xxlTheme } from "../../../../../../../styles/xxl-theme";
const { spaces, typography } = xxlTheme;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #d7d7d7;
  padding: 0 ${spaces.micro};

  ${MQ("tabletHorizontal")} {
    margin-top: ${spaces.smallLarge};
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
  padding: 8px 5px;
  color: #000;
  font-size: 0.9rem;
  font-family: ${typography.mediumRegular};
  line-height: 19px;
  background: transparent;
  border: 0;
  outline: none;
  -webkit-appearance: none;
  &::-webkit-search-cancel-button {
    display: none;
  }

  &:focus {
    outline: none;
  }
`;
