import React from "react";
import type { MouseEvent } from "react";
import { urlToRelativeHref } from "../../utils/xxl-url";
import { SORT_QUERY_PARAMETER } from "../Search/Constants";
import {
  useFilterContext,
  UPDATE_DRAWER_FILTER_SELECTED,
} from "../Filter/FilterState";
import { DUMMY_URL } from "../../constants";
import { RadiobuttonWrapper } from "../../styled";
import {
  FilterSortLabelStyled,
  FilterSortLinkStyled,
} from "../../components/Filter/FilterBar/FilterBar.styled";
import { removeStickyFilter } from "../Filter/FilterHelper";
import { useSharedData } from "../../contexts/SharedData";
import { useMediaQuery } from "@mui/material";
import { mobileAndTabletNotHorizontalMediaQuery } from "../../utils/xxl-screen";
import { scrollToFilterBar } from "../../utils/xxl-scroll-to-filter-bar";
import noop from "lodash/noop";

type SortItemProps = {
  idPrefix?: string;
  isSelected: boolean;
  name: string;
  onClick?: (value: string) => void;
  value: string;
};

export const SortItem: React.FunctionComponent<SortItemProps> = ({
  idPrefix = "",
  isSelected,
  name,
  onClick = noop,
  value,
}) => {
  const { dispatch: filterDispatch, state: filterState } = useFilterContext();
  const { isStickyFilterBar } = filterState;
  const isMobileOrTablet = useMediaQuery(
    mobileAndTabletNotHorizontalMediaQuery
  );
  const { featureToggles } = useSharedData().data;
  const { toggle_sticky_filter } = featureToggles;

  const handleClickSort = (event: MouseEvent): void => {
    event.preventDefault();
    if (isSelected) {
      return;
    }
    onClick(value);
    if (toggle_sticky_filter) {
      return;
    }
    filterDispatch({
      type: UPDATE_DRAWER_FILTER_SELECTED,
      payload: true,
    });
    if (isMobileOrTablet) {
      scrollToFilterBar();
    } else if (isStickyFilterBar) {
      removeStickyFilter();
    }
  };

  const dummyUrl = new URL(DUMMY_URL);
  dummyUrl.searchParams.set(SORT_QUERY_PARAMETER, value);
  const href = urlToRelativeHref(dummyUrl).substring(1);

  return (
    <FilterSortLinkStyled
      className={`test-category-list-sort__item-${idPrefix}${value}`}
      href={href}
      key={`${idPrefix}${value}`}
      onClick={handleClickSort}
    >
      <RadiobuttonWrapper key={`${idPrefix}${value}`}>
        <input
          type="radio"
          name={`${idPrefix}sortItem`}
          id={`${idPrefix}${value}`}
          checked={isSelected}
          readOnly={true}
        />
        <FilterSortLabelStyled htmlFor={`${idPrefix}${value}`}>
          {name}
        </FilterSortLabelStyled>
      </RadiobuttonWrapper>
    </FilterSortLinkStyled>
  );
};
