import React, { useState } from "react";
import { Slider, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Label, Value, SliderContainer } from "./RangeSlider.styled";
import Breakpoints from "../../../styles/breakpoints.config";
import {
  mobileMediaQuery,
  mobileAndTabletNotHorizontalMediaQuery,
} from "../../../utils/xxl-screen";
import { hexToRGB } from "../../../utils/xxl-hex-to-rgb";
import { xxlTheme } from "../../../styles/xxl-theme";
import {
  useFilterContext,
  UPDATE_DRAWER_FILTER_SELECTED,
} from "../../Filter/FilterState";
import { removeStickyFilter } from "../../Filter/FilterHelper";
import { scrollToFilterBar } from "../../../utils/xxl-scroll-to-filter-bar";
import { useSharedData } from "../../../contexts/SharedData";
import type { RangeSliderProps } from "./RangeSlider.types";

const { colors } = xxlTheme;
const thumbDiameterSmall = 22;
const thumbDiameterLarge = 30;
const marginTopSmall = -1;
const borderInPx = 8;
const boxShadowSizing = `0px 0px 0px ${borderInPx}px`;

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: "var(--color-xxl-web-black)",
  "& .MuiSlider-thumb": {
    height: thumbDiameterLarge,
    width: thumbDiameterLarge,
    [theme.breakpoints.up(Breakpoints.tablet)]: {
      height: thumbDiameterSmall,
      width: thumbDiameterSmall,
      marginTop: marginTopSmall,
    },
    backgroundColor: "var(--color-xxl-web-black)",

    "&:focus, &:hover": {
      boxShadow: `${boxShadowSizing} rgb(0, 0 ,0, 0.15)`,
    },
    "&.Mui-focusVisible": {
      boxShadow: `${boxShadowSizing} rgb(0,0,0, 0.15)`,
    },
    "&:active": {
      boxShadow: `${boxShadowSizing} ${hexToRGB(colors.xxlGreen, 0.15)}`,
    },
  },
  "& .Mui-active": {
    backgroundColor: "var(--color-xxl-green)",
  },
  ".MuiSlider-track": {
    height: 3,
    borderRadius: 4,
  },
  ".MuiSlider-rail": {
    color: "var(--color-xxl-gray)",
    height: 3,
    borderRadius: 4,
  },
}));

const RangeSlider = ({
  id,
  max,
  min,
  onUpdate,
  selectedMax,
  selectedMin,
}: RangeSliderProps) => {
  const [value, setValue] = useState<number[]>([
    selectedMin ?? min,
    selectedMax ?? max,
  ]);
  const isMobile = useMediaQuery(mobileMediaQuery);
  const containerPadding =
    (isMobile ? thumbDiameterLarge : thumbDiameterSmall) / 2 + borderInPx;
  const { dispatch, state } = useFilterContext();
  const { isStickyFilterBar } = state;
  const isMobileOrTablet = useMediaQuery(
    mobileAndTabletNotHorizontalMediaQuery
  );
  const { featureToggles } = useSharedData().data;
  const { toggle_sticky_filter } = featureToggles;

  const onChange = (newValue: number | number[], isCommitted: boolean) => {
    if (!Array.isArray(newValue)) {
      throw new Error(
        `[RangeSlider] The value should be an array. The current type is ${typeof newValue}. Value: ${newValue}.`
      );
    }

    setValue(newValue);

    if (!isCommitted) {
      return;
    }

    const [newMin, newMax] = newValue;

    onUpdate({
      min: newMin,
      max: newMax,
    });

    if (toggle_sticky_filter) {
      if (isStickyFilterBar) {
        if (isMobileOrTablet) {
          scrollToFilterBar();
        } else {
          removeStickyFilter();
        }
      }

      dispatch({
        type: UPDATE_DRAWER_FILTER_SELECTED,
        payload: true,
      });
    }
  };

  React.useEffect(() => {
    const newMin =
      selectedMin === undefined || selectedMin < min ? min : selectedMin;
    const newMax =
      selectedMax === undefined || selectedMax > max ? max : selectedMax;

    setValue([newMin, newMax]);
  }, [max, min, selectedMin, selectedMax]);

  return (
    <>
      <Label htmlFor="price-slider">
        <Value data-testid={`${id}-min`}>{value[0]}</Value>
        <Value data-testid={`${id}-max`}>{value[1]}</Value>
      </Label>
      <SliderContainer sidePadding={containerPadding}>
        <CustomSlider
          getAriaValueText={(value) => value.toString()}
          id={id}
          max={max}
          min={min}
          onChange={(_, val) => onChange(val, false)}
          onChangeCommitted={(_, val) => onChange(val, true)}
          value={value}
        />
      </SliderContainer>
    </>
  );
};

export { RangeSlider };
