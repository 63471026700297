import {
  DESKTOP_HEIGHT_WITH_HEADER_LINKS,
  MOBILE_HEIGHT_WITH_HEADER_LINKS,
} from "react-app/src/components/Header/constants";
import { Pagination as MuiPagination } from "@mui/material";
import { hasNoValue } from "@xxl/common-utils";
import { color } from "@xxl/theme";
import { XxlButton } from "react-app/src/components/Common/XxlButton";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";
import spaces from "react-app/src/styles/theme/spaces";

export type PaginationProps = {
  disabled?: boolean;
  onChange: (pageNr: number) => void;
  page: number;
  pageCount: number;
  relativePagingUrl?: string;
  scrollToRef: HTMLElement | null;
};

const muiStyleOverrides = {
  ".MuiPagination-ul": {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  ".MuiPaginationItem-root": {
    borderRadius: 0,
    height: "24px",
    width: "24px",
    minWidth: "24px",
    boxSizing: "border-box",
  },
  ".MuiPaginationItem-root:hover": {
    backgroundColor: color.green.hex,
  },
  ".MuiPaginationItem-root.Mui-selected": {
    backgroundColor: color.green.hex,
  },
  ".MuiPaginationItem-previousNext": {
    backgroundColor: color.webBlack.hex,
    color: color.white.hex,
    margin: 0,
  },
  ".MuiPaginationItem-previousNext:hover": {
    color: color.black.hex,
  },
};

const EXTRA_MARGIN_ON_DESKTOP = 154;
const EXTRA_MARGIN_ON_MOBILE = 172;
const SCROLL_DELAY_IN_MS = 10;

const Pagination = ({
  onChange,
  page,
  pageCount,
  disabled = false,
  relativePagingUrl,
  scrollToRef,
}: PaginationProps) => {
  const { t } = useTranslations();
  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");
  const handleOnChange = (pageNumber: number) => {
    onChange(pageNumber);
    if (hasNoValue(scrollToRef)) {
      return;
    }
    const offset = isLaptop
      ? -DESKTOP_HEIGHT_WITH_HEADER_LINKS - EXTRA_MARGIN_ON_DESKTOP
      : -MOBILE_HEIGHT_WITH_HEADER_LINKS - EXTRA_MARGIN_ON_MOBILE;
    const top =
      scrollToRef.getBoundingClientRect().top + window.scrollY + offset;

    setTimeout(function () {
      window.scrollTo({ top });
    }, SCROLL_DELAY_IN_MS);
  };

  return (
    <XxlStack width={"100%"} flexDirection={"row"} justifyContent={"center"}>
      <XxlStack
        gap={spaces.large}
        width={isLaptop ? "440px" : "351px"}
        paddingBottom={spaces.smallHuge}
        paddingTop={spaces.smallHuge}
      >
        <XxlButton
          href={page !== pageCount ? relativePagingUrl : undefined}
          variant={"secondary"}
          disabled={page === pageCount || disabled}
          onClick={(e) => {
            e.preventDefault();
            handleOnChange(page + 1);
          }}
          data-testid={"plp-next-page-btn"}
        >
          {t("general.next.page")}
        </XxlButton>
        <MuiPagination
          page={page}
          sx={muiStyleOverrides}
          count={pageCount}
          onChange={(_, pageNr) => handleOnChange(pageNr)}
          disabled={disabled}
        />
      </XxlStack>
    </XxlStack>
  );
};

export { Pagination };
