import type { SiteIdentifier } from "@xxl/common-utils";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { ContentApi, Store } from "@xxl/content-api";
import type { TranslationKey } from "../../../translations";
import type { PreferredStoreIds } from "../../../utils/Cookie";
import { getPreferredStoresCookie } from "../../../utils/Cookie";
import { AVAILABILITY } from "../../Search/SearchFetchProductsHelper.types";

export const INPUT_ID = {
  ONLINE: "showOnlineAssortment",
  STORE: "showStoreAssortment",
} as const;
export type TInput_ID = (typeof INPUT_ID)[keyof typeof INPUT_ID];
type AvailabilityInput = {
  id: TInput_ID;
  value: AVAILABILITY;
  labelTranslationKey: TranslationKey;
};

export const availabilityInputs: AvailabilityInput[] = [
  {
    id: INPUT_ID.ONLINE,
    value: AVAILABILITY.ONLINE,
    labelTranslationKey: "general.available.online",
  },
  {
    id: INPUT_ID.STORE,
    value: AVAILABILITY.STORE,
    labelTranslationKey: "general.available.in.store",
  },
];

type GetSelectedStoresParams = {
  contentApi: ContentApi;
  siteUid: SiteIdentifier;
  /** If true, the user can select multiple stores. */
  toggle_click_and_collect_for_goods_only_in_store: boolean;
};
export const getSelectedStores = async ({
  contentApi,
  siteUid,
}: GetSelectedStoresParams): Promise<{
  stores: Store[];
  availability: AVAILABILITY[];
}> => {
  let idsOfStoresToFetch: PreferredStoreIds = [];
  let availability: AVAILABILITY[] = [];

  const preferredStores = getPreferredStoresCookie();
  if (preferredStores !== null) {
    idsOfStoresToFetch = preferredStores.ids;
    availability = preferredStores.availability;
  }

  const getStore: (ids: string[]) => Promise<Store[]> = async (ids) => {
    if (ids.length === 0) {
      return [];
    }
    const fetchOneStore = async (id: string) => {
      const response = await contentApi.getStore(siteUid, id);
      const { result: stores } = response.data;

      if (isNotNullOrUndefined(stores) && isNotNullOrUndefined(stores[0])) {
        return stores[0];
      }
      return null;
    };

    const storePromises = ids.map(fetchOneStore);
    const preferredStoresArray = await Promise.all(storePromises);

    return preferredStoresArray.filter(isNotNullOrUndefined);
  };

  try {
    const stores = await getStore(idsOfStoresToFetch);
    return { stores, availability };
  } catch (error) {
    console.error("Could not get store.", error);
  }

  return { stores: [], availability };
};

type GetStoreCheckboxLabelParams = {
  t: (key: TranslationKey) => string;
  selectedStores: Store[];
};
export const getStoreCheckboxLabel = ({
  selectedStores,
  t,
}: GetStoreCheckboxLabelParams) => {
  switch (selectedStores.length) {
    case 0:
      return t("general.available.in.store");
    case 1:
      return selectedStores[0].name;
    default:
      return t("general.available.in.store.multiple");
  }
};

export const getMobileSelectedStoreLabel = (
  t: (key: TranslationKey) => string,
  stores: Store[],
  isRegionSelected: boolean,
  regionName = ""
) => {
  if (isRegionSelected) {
    return `${regionName}, ${t("storefinder.warehouses.all")}`;
  }
  switch (stores.length) {
    case 0:
      return t("general.available.in.store");
    case 1:
      return stores[0].name;
    default:
      return t("general.available.in.store.multiple");
  }
};
